export default {
    route:{
        '首页': 'Homepage',
        '产品': 'Product',
        '公司': 'Company',
        '市场与活动': 'Marketand Activities',
        '服务与支持': 'Serviceand Support',
        'SUZUKI铃木船用发动机': 'SUZUKI Suzuki Marine Engine',
        'EXPLOMAR擎波探索电动机': 'EXPLOMAR explores electric motors',
        'JMP水泵': 'JMP water pump',
        'SeaFirst液压系统': 'SeaFirst Hydraulic System',
        'Pretech方向系统': 'Pretech directional system',
        'Scepter油箱': 'Scepter fuel tank',
        'CANSB油箱': 'CANSB fuel tank',
        'Torqeedo电动机': 'Torqeedo electric motor',
        'Nauticus压浪板': 'Nauticus Wave Plate',
        'SOLAS螺旋桨': 'SOLAS propeller',
        'KDP螺旋桨': 'KDP propeller',
        'Misty Harbor船': 'Misty Harbor Ship',
        '半固态电池HEDB240': 'Semi solid state battery HEDB240',
        '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择': 'Increasing the low-speed output of the AC generator eliminates the need to worry about high energy consumption of fishing boats, making it an ideal choice for your sea fishing journey',
        '高能推进水上出行的电气化与智能化纯电驱动': 'Electrification and intelligent pure electric drive for high-energy propulsion of water travel',
        '电动机': 'Electric Outboard',
        '三元锂电池HEDB125': 'HEDB125',
        '三元锂电池HEDB40': 'HEDB40',
        '充电桩': 'Charging Collection',

        '规格': 'Specification',
        '建议艉板高度 mm': 'Recommended Transom Height (mm)',
        '启动系统': 'Starting System',
        '重量 kg*': 'Weight (kg)',
        '发动机类型': 'Valve Train',
        '燃油供给系统': 'Fuel Delivery System',
        '气缸': 'Cylinder',
        '活塞排量 cm3': 'Displacement(cm³)',
        '缸径X冲程 mm': 'Bore and Stroke (mm)',
        '最大输出功率 kw': 'Maximum Output (KW)',
        '全节气门工作范围rpm': 'Operation Range (rpm)',
        '操控方式': 'Control System',
        '油底壳容量L': 'Oil Pan Capacity (L)',
        '推荐燃油': 'Recommended Fuel',
        '燃油箱容量L': 'Fuel tank capacity (L)',
        '交流发电机': 'Alternator',
        '调整方式': 'Trim Type',
        '传动比': 'Gear Ratio',
        '档位': 'Gears',
        '排气': 'Exhaust',
        '螺旋桨选型(螺距 )': 'Propeller Selection (pitch)',

        '电动': 'Electric',
        '电动/手动': 'Electric/Manual',
        '无电池电子燃油喷射': 'Battery-less Electronic Fuel Injection',
        '直列 3': 'Inline 3',
        '直列 4': 'Inline 4',
        '舵柄': 'Mechanical',
        '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)': 'RON91/AKI87',
        '独立式(选装)25': 'Freestanding (optional) 25',
        '通过螺旋桨桨毂排气': 'Exhaust through the propeller hub',
        '气动辅助倾角调整': 'Gas Assisted Trim &Tilt',
        '遥控(机械)': 'Drive By Wire (Mechanical)',
        '电力倾角调整': 'Power Trim and Tilt',
        '电子燃油喷射': 'Electronic Fuel Injection',
        'DOHC 12气门': 'DOHC 12-Valve',
        'DOHC 24气门': 'DOHC 24-Valve',
        'DOHC 16气门': 'DOHC 16-Valve',
        '电动升降和起翘': 'Power Trim and Tilt',
        '机械': 'Mechanical',
        '机 械': 'Mechanical',
        '遥控(DBW)': 'Drive By Wire',
        //
        '推荐艉肋高度(mm)': 'Recommended Transom Height (mm)',
        '重量千克*1': 'Weight (kg)',
        '气阀机构': 'Valve Train',
        '气阀机构驱动器': 'Fuel Delivery System',
        '排量(cm3)': 'Displacement(cm³)',
        '最大输出功率(Kw)': 'Maximum Output (KW)',
        '缸径和冲程(mm)': 'Bore and Stroke (mm)',
        '工作范围(rpm)': 'Operation Range (rpm)',
        '燃油输送系统': 'Control System',
        '油底壳容量(L)': 'Oil Pan Capacity (L)',
        '升降类型': 'Trim Type',
        '控制系统': 'control system',
        '推荐燃油*2': 'Recommended Fuel',
        '螺旋桨选择(螺距)': 'Propeller Selection (pitch)',

        '双顶置凸轮轴 24-阀': 'DOHC 24-Valve',
        '链条': 'chain',
        '电喷系统': 'Electronic Fuel Injection',
        '电动升降和起跷': 'Power Trim and Tilt',
        '线传驱动': 'Drive By Wire',

        '输入电压': 'input Voltage',
        '转速范围(螺旋浆)': 'RPM Range (Propeller)',
        '最大输出扭矩': 'Propeller Max Output Torque',
        '尺寸(宽度*深度*高度)': 'Dimension (Width*Depth*Height)',
        // '控制系统': 'Control System',
        '轴长': 'Shaft Length',
        '发动机': 'Motor',
        '起翘角度': 'Maximum Tilt Range',
        '配平角度': 'Maximum Trim Range',
        '转向角度': 'Degree of Steering',
        '冷却系统': 'Cooling System',
        '建议螺旋桨': 'Propeller Suggested',

        'SmartCaptain智驾系统': 'SmartCaptain System',
        'AFPMM/轴向通量永磁电机': 'AFPMM/Axial Flux Permanent Magnet Motor',
        '专利闭式液冷系统': 'Patented Closed Liquid Cooling System',

        '15.75 *15/3叶片(极端速度)14-1/2*25/3叶片(完美加速)14.6*19/4叶片(安静)': '15.75 *15 / 3 blades (Extreme speed)14-1/2 *25 /3 blades (Perfect acceleration)14.6*19 /4 blades (Quiet)',
        '13 1/2*15*3': '13 1/2*15*3',

        '前操与后操': 'Control Box and Tiller',
        'PMSM(X针)永磁同步电机(X针)': 'PMSM(X-pin)/PermanentMagnet Synchronous Motor(X-pin)',

        '电池容量': 'Energy Capacity',
        '重量': 'Weight',
        '电池类型': 'Cell Type',
        '额定电压': 'Nominal Voltage',
        '能量密度': 'Pack Energy Density',
        '工作温度': 'Working Temperature',
        '包装材质': 'Pack Material',
        // '冷却系统': 'Cooling System',
        '外壳防护等级': 'Protection',
        // '尺寸(宽度*深度*高度)': 'Dimension (Width*Depth*Height)',
        '充电率': 'Charging Rate',
        '放电率': 'Discharging Rate',
        '充电器': 'Charger',

        '不锈钢': 'Stainless',
        '闭式液冷系统': 'Closed Systematic Cooling Solution',
        '7kW直流电 Max 120kW 直流电(两小时内充满电)': '7kW DCMax 120kW DC (Fully charged in two hours)',

        '三元锂': 'NCM',
        '7kW直流电 Max 40kW 直流电(两小时内充满电)': '7kW DCMax 40kW DC (Fully charged in two hours)',
        '7kW直流电 Max 125kW 直流电(两小时内充满电)': '7kW DCMax 125kW DC (Fully charged in two hours)',

        '机身尺寸': 'Size',
        // '重量': 'product',
        '额定输出功率': 'Output Rated Power',
        '输入电压范围': 'Input Voltage Range',
        '输出电压范围': 'Output Voltage Range',
        '输入电流范围': 'Input Current Range',
        '输出电流范围': 'Output Current Range',
        '工作频率': 'Working Frequency',
        '充电接口': 'Charging Plug',
        '冷却方式': 'Cooling Method',
        '防护等级': 'Protection Level',
        '490*385*185mm(不含枪头、提手、脚轮)': '490*385*185mm (Charging gun tip, handle, casters excluded)',
        '680*230*450mm(包含轮子)': '680*230*450mm(Wheels included)',
        '26kg(参考值，含枪线)': '26kg (Reference, charging cable included)',
        '90-265Vac:230Vac(额定电压)': '90-265Vac ;230Vac (Rated Voltage)',
        '风冷': 'Forced-air cooling',
        '7kW充电桩': '7kW DC Portable Charger',
        '40kW充电桩': '40kW DC Movable Charger',
        '120kW充电桩': '120kW Dual-Gun Charging Station',






    },
    '搜索': 'search',
    '苏州希勒姆贸易有限公司': 'Suzhou SealMarine Trade Co., Ltd',
    '苏州希勒姆贸易有限公司，拥有超过20年的海事产品专业经验，已发展成为业内值得信赖的合作伙伴。致力于提供卓越的海事产品与服务，通过与众多顶级品牌和产品的深度合作，建立了强大的行业地位。'
        : 'Suzhou SealMarine Trade Co., Ltd., with over 20 years of experience in marine business, has become a trusted partner in the industry. Dedicated to providing genuine marine products and services, through collaborations with numerous top brands and products around world. SealMarine has played a key role in the business.',
    '作为多个知名品牌在中国的关键经销商，包括Suzuki铃木发动机的一级代理、Explomar擎波探索电动机的总经销、韩国SeaFirst液压系统和JMP水泵的独家经销等，覆盖了船用发动机、发电机冷却部件等多个关键领域。':
        'SealMarine is a master distributor in China for several international marine leading brands, including Suzuki marine engines, Explomar marine electric motors, SeaFirst hydraulic systems and JMP pumps, covers multiple critical areas such as marine engines and components.',
    '同时，与加拿大Scepter、意大利Can、德国Torqeedo & Textron Moto以及奥地利Aquamot等国际品牌保持紧密的合作伙伴关系，为客户提供专业的海事解决方案。除此之外， 还与美国Misty Harbor & Caravelle boat group等国内外知名船只品牌携手，致力于为客户带来更多高品质游艇的选择。':'SealMarine also keeps partnerships with a lot of brands around world such as: Canada\'s Scepter, Italy\'s CANSB, Germany\'s Torqeedo & Textron Moto, and Austria\'s Aquamot and more, so we can offer professional marine solutions to customers around China. In addition, we are collaborating with well-known boat brands from the US and abroad, such as Misty Harbor & Caravelle boat group, provide customers with a wide selection of high-quality boats & yachts.',
    '始终将客户的满意度作为工作的核心，通过持续优化产品和服务，赢得了广泛的客户信赖。未来，将继续秉承合作与发展的原则，不断追求卓越，为客户带来更加完善的海事产品及服务体验。':'Customer satisfaction always be the core concept of SealMarine, win-win policy is our way of business philosophy. By continuously improving products and services, we have earned widespread trust among customers. Future ahead, we will keep going forward on cooperation and development, seeking high quality marine products and enhance service, providing perfect user experience for our customers!',
    '苏州市工业园区泾茂路369号新海宜科技园南区H幢101室': 'Room 101, Building H, South District, Xinhaiyi Science and Technology Park, No. 369 Jingmao Road, Suzhou Industrial Park',
    '© 2024 SealMarine - 苏州希勒姆贸易有限公司 版权所有': '© 2024 SealMarine - Suzhou Xilum Trading Co., Ltd. All rights reserved',
    '提出问题': 'Ask a question',
    '网站通知': 'Website Notice',
    '法律信息': 'legal information',
    '数据隐私': 'data privacy',
    '举报系统': 'Reporting system',
    '苏ICP备2021007876号': 'Su ICP No. 2021007876',
    '隐私设置': 'Privacy settings',
    '告诉我们您的意见和想法': 'Tell us your opinions and thoughts',
    '2022年铃木船外机产品说明会-江苏地区': '2022 Suzuki outboard motor product briefing - Jiangsu region',
    '2024年微山湖铃木售后服务活动': '2024 Weishan Lake Suzuki After sales Service Event',
    '联系方式': 'Contact Information',
    '专业的技术团队，擅于解决产品选型、产品信息咨询、产品应用等问题，为您提供苏州希勒姆贸易全线产品的技术支持服务': 'Our professional technical team is skilled in solving problems such as product selection, product information consultation, and product application, providing you with technical support services for all products of Suzhou Hilem Trading',
    '服务时间': 'Service Time',
    '09:00-17:00（工作日': '09:00-17:00 (weekdays)',
    '电子文档': 'Electronic documents',
    '下载电子文档': 'Download electronic documents',
    '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择': 'Increasing the low-speed output of the AC generator eliminates the need to worry about high energy consumption of fishing boats, making it an ideal choice for your sea fishing journey',
    '高能推进水上出行的电气化与智能化纯电驱动': 'Electrification and intelligent pure electric drive for high-energy propulsion of water travel',
    '15.75 *15/3叶片(极端速度)14-1/2*25/3叶片(完美加速)14.6*19/4叶片(安静)': '15.75 *15 / 3 blades (Extreme speed)14-1/2 *25 /3 blades (Perfect acceleration)14.6*19 /4 blades (Quiet)',
    '2020年铃木船外机优秀经销商颁奖典礼': '2020 Suzuki outboard motor excellent dealer award ceremony',
    '2021年铃木船外机优秀经销商颁奖典礼': '2021 Suzuki outboard motor excellent dealer award ceremony',
    '2022年铃木船外机全国经销商大会': '2022 Suzuki Offshore Engine National Dealer Conference',
    '2022年苏州莲花岛售后服务活动': '2022 Suzhou Lianhua Island After sales Service Activity',
    '2023年铃木船外机优秀经销商颁奖典礼': '2023 Suzuki outboard motor excellent dealer award ceremony',
}
