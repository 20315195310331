<template>
  <div class="breadcrumb" v-if="levelList.length>1">
    <el-breadcrumb separator="/" class="breadcrumb-item">
      <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <span v-if="item.redirect==='noRedirect'|| index === levelList.length-1" class="no-redirect">{{generateTitle(item.meta.title)}}</span>
         <a v-else  @click.prevent="handleLink(item)"> {{generateTitle(item.meta.title)}}</a>
      </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
<!--    {{levelList}}-->
<!--    <div class="content">-->
<!--      <div class="right-menu">-->
<!--        <el-dropdown class="avatar-container" trigger="click">-->
<!--          <div class="avatar-wrapper">-->
<!--            <img :src="'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80'" class="user-avatar" alt="">-->
<!--            <i class="el-icon-caret-bottom"/>-->
<!--          </div>-->
<!--          <el-dropdown-menu slot="dropdown" class="user-dropdown">-->
<!--            <router-link to="/">-->
<!--              <el-dropdown-item>-->
<!--                Home-->
<!--              </el-dropdown-item>-->
<!--            </router-link>-->
<!--            <el-dropdown-item divided @click.native="logout">-->
<!--              <span style="display:block;">Log Out</span>-->
<!--            </el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </el-dropdown>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {generateTitle} from "@/utils/i18n";
export default {
  name: "Breadcrumb",
  data() {
    return {
      levelList: null,
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    generateTitle,
    async logout() {
      // // await this.$store
      // console.log( this.$store.dispatch)
      // return
      await this.$store.dispatch('logout')
      await this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    getBreadcrumb() {
      // console.log(this.$route.matched)
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      const first = matched[0]
      // console.log(matched)
      // return
      if(!this.isDashboard(first)) {
        matched = [{ path : '/',redirect: '/homepage', meta: { title: '首页' }}].concat(matched)
      }
      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
      console.log(matched)
      console.log(this.levelList,'是的是的')
    },
    isDashboard(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'Workbench'.toLocaleLowerCase()
    },
    handleLink(item) {
      const { redirect, path } = item
      console.log(redirect,'是丢眼')
      // return;
      if(redirect) {
        this.$router.push(redirect)
        return
      }
      // this.$router.push(this.pathCompile(path))
    }
  }
}
</script>

<style scoped lang="scss">
  .breadcrumb {
    width: 100%;
    padding: 10px 0;
    //height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .breadcrumb-item {
    padding-left: 20px;
  }

  /* breadcrumb transition */
  .breadcrumb-enter-active,
  .breadcrumb-leave-active {
    transition: all .5s;
  }

  .breadcrumb-enter,
  .breadcrumb-leave-active {
    opacity: 0;
    transform: translateX(20px);
  }

  .breadcrumb-move {
    transition: all .5s;
  }

  .breadcrumb-leave-active {
    position: absolute;
  }

  .right-menu {
    padding-right: 20px;
  }
  .user-avatar {
    width: 40px;
    height: 40px;
  }
</style>
