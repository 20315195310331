<template>
  <div class="layout">
    <div ref="NavMenu">
      <nav-menu class="nav-menu" ></nav-menu>
    </div>
    <div class="main" :style="{'min-height' :`calc(100vh - ${Footer+NavMenu}px)`}">
      <div ref="breadcrumb">
        <breadcrumb></breadcrumb>
      </div>
      <app-main></app-main>
    </div>
    <div ref="Footer">
      <Footer class="Footer"></Footer>
    </div>

    <!--    <router-view />-->
  </div>
</template>

<script>
import { NavMenu, Breadcrumb, AppMain, Footer } from "./components/App"

export default {
  name: 'Layout',
  components: {
    NavMenu,
    Breadcrumb,
    AppMain,
    Footer
  },
  data() {
    return {
      NavMenu: 0,
      Footer: 0,
      breadcrumb: 0
    };
  },
  mixins: [],
  computed: {
    key() {
      return this.$route.path
    },


  },
  mounted() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDimensions);
  },
  created() {
  },
  methods: {
    updateDimensions() {
      this.$nextTick(()=>{
        setTimeout(() => {
          this.NavMenu = this.$refs.NavMenu.offsetHeight;
          this.Footer = this.$refs.Footer.offsetHeight;
          this.breadcrumb = this.$refs.breadcrumb.offsetHeight;
          this.$store.dispatch("setHeight", this.$refs.NavMenu.offsetHeight+this.$refs.Footer.offsetHeight);
          this.$store.dispatch("setBreadcrumbHeight", this.$refs.breadcrumb.offsetHeight);
        }, 300)

      })

    }
  }
}
</script>

<style scoped lang="scss">
.layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100vh;
  //overflow: hidden;
}
.nav-menu {
  //max-height: 10vh;
  //min-height: 14.9vh;
}
.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  //max-height: 61vh;
  //min-height: calc(100vh - 30vh);
  //overflow: auto!important;
  //overflow: hidden;
}
.Footer {
  //z-index: 999;
  //max-height: 30vh;
  //min-height: 24vh;
}
</style>
