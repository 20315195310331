import { login } from "@/api/user";
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
export default {
  state: {
      token: getToken(),
      name: '',
      avatar: ''
  },
  mutations: {
    RESET_STATE: (state) => {
      // Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        const data  = user
          commit('SET_TOKEN', 'token')
          setToken('token')
          resolve()
        // console.log(data)
        // login(data).then(result => {
        //   const { data } = result
        //   console.log('撒大大是',data.token)
        //   // return
        //   commit('SET_TOKEN', data.token)
        //   setToken(data.token)
        //   resolve()
        // }).catch(error => {
        //   console.log('十点半')
        //   reject(error)
        // })
      })
    },
    // user logout
    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        removeToken()
        resetRouter()
        commit('RESET_STATE')
        resolve()
        return
        logout(state.token).then(() => {
          removeToken() // must remove  token  first
          resetRouter()
          commit('RESET_STATE')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // remove token
    resetToken({ commit }) {
      return new Promise(resolve => {
        removeToken() // must remove  token  first
        commit('RESET_STATE')
        resolve()
      })
    }
  }
}
