import Cookies from "js-cookie";

export default {
    state: {
        language: Cookies.get('language') || 'zh',
        height: Cookies.get('height') || 0,
        breadcrumbHeight: Cookies.get('breadcrumbHeight') || 0,
    },
    mutations: {
        // ...其他配置项省略...
        SET_LANGUAGE: (state, language) => {
            state.language = language
            Cookies.set('language', language)
        },
        SET_HEIGHT: (state, height) => {
            state.height = height
            Cookies.set('height', height)
        },
        setBreadcrumbHeight: (state, breadcrumbHeight) => {
            state.breadcrumbHeight = breadcrumbHeight
            Cookies.set('breadcrumbHeight', breadcrumbHeight)
        }
    },
    actions: {
        // ...其他配置项省略...
        setLanguage({commit}, language) {
            commit('SET_LANGUAGE', language)
        },
        setHeight({commit}, height) {
            commit('SET_HEIGHT', height)
        },
        setBreadcrumbHeight({commit}, height) {
            commit('setBreadcrumbHeight', height)
        },
    }
}
