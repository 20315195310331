// 翻译router.meta。标题，用于面包屑侧边栏tagsview
export function generateTitle(title) {
    const hasKey = this.$te('route.' + title)
    if(title==='15.75 *15/3叶片(极端速度)14-1/2*25/3叶片(完美加速)14.6*19/4叶片(安静)'){
        const translatedTitle = this.$t('15.75 *15 / 3 blades (Extreme speed)14-1/2 *25 /3 blades (Perfect acceleration)14.6*19 /4 blades (Quiet)')
        return translatedTitle

    }
    if (hasKey) {
        // $t :this method from vue-i18n, inject in @/lang/index.js
        const translatedTitle = this.$t('route.' + title)

        return translatedTitle
    }
    return title
}
