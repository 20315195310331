<template>
  <div class="app-main" >
    <router-view :key="key" />
<!--    <router-view :key="`${key}`"  />-->
<!--    <router-link :to="`${key}1`">第一个</router-link>-->
<!--    <keep-alive>-->
<!--      <router-view></router-view>-->
<!--    </keep-alive>-->
  </div>

</template>

<script>
export default {
  name: "AppMain",
  created() {
    // console.log(this.$router.options.routes,'wqweqeqw')
    console.log(this.$route.path,'wqweqeqw')
  },
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped lang="scss">
  .app-main {
    position: relative;
    flex: auto;
    height: 100%;
    //max-height: 49vh;
    //overflow: hidden;
    transition: .3s;
    //opacity: 0;
    //background: #f2f2f2;
    //background: red;
  }
</style>
