export default {
    route: {
        homepage: '首页',
        product: '产品',
        company: '公司',
        MarketandActivities: '市场与活动',
        ServiceandSupport: '服务与支持',
        'SUZUKI Suzuki Marine Engine': 'SUZUKI铃木船用发动机',
        'EXPLOMAR explores electric motors with waves': 'EXPLOMAR擎波探索电动机',
        'JMP water pump': 'JMP水泵',
        'SeaFirst Hydraulic System': 'SeaFirst液压系统',
        'Pretech': 'Pretech',
        'Scepter fuel tank': 'Scepter油箱',
        'CANSB fuel tank': 'CANSB油箱',
        'Torqeedo electric motor': 'Torqeedo电动机',
        'Nauticus Wave Plate': 'Nauticus压浪板',
        'SOLAS propeller': 'SOLAS螺旋桨',
        'KDP propeller': 'KDP螺旋桨',
        'Misty Harbor Ship': 'Misty Harbor船',
        'Semi solid state battery': '半固态电池',
        'Increasing the low-speed output of the AC generator eliminates the need to worry about high energy consumption of fishing boats, making it an ideal choice for your sea fishing journey': '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
        'Electrification and intelligent pure electric drive for high-energy propulsion of water travel': '高能推进水上出行的电气化与智能化纯电驱动',

        'Specification': '规格',
        'Recommended Transom Height (mm)': '建议艉板高度 mm',
        'Starting System': '启动系统',
        'Weight (kg)': '重量 kg*',
        'Valve Train': '发动机类型',
        'Fuel Delivery System': '燃油供给系统',
        'Cylinder': '气缸',
        'Displacement(cm³)': '活塞排量 cm3',
        'Bore and Stroke (mm)': '缸径X冲程 mm',
        'Maximum Output (KW)': '最大输出功率 kw',
        'Operation Range (rpm)': '全节气门工作范围rpm',
        'Control System': '操控方式',
        'Oil Pan Capacity (L)': '油底壳容量L',
        'Recommended Fuel': '推荐燃油',
        'Fuel tank capacity (L)': '燃油箱容量L',
        'Alternator': '交流发电机',
        'Trim Type': '调整方式',
        'Gear Ratio': '传动比',
        'Gears': '档位',
        'Exhaust': '排气',
        'Propeller Selection (pitch)': '螺旋桨选型(螺距 )',
        '15.75 *15/3叶片(极端速度)14-1/2*25/3叶片(完美加速)14.6*19/4叶片(安静)': '15.75 *15 / 3 blades (Extreme speed)14-1/2 *25 /3 blades (Perfect acceleration)14.6*19 /4 blades (Quiet)',


    },
    'search': '搜索',
    'Suzhou SealMarine Trade Co., Ltd': '苏州希勒姆贸易有限公司',
    'Suzhou SealMarine Trade Co., Ltd., with over 20 years of experience in marine business, has become a trusted partner in the industry. Dedicated to providing genuine marine products and services, through collaborations with numerous top brands and products around world. SealMarine has played a key role in the business': '苏州希勒姆贸易有限公司，拥有超过20年的海事产品专业经验，已发展成为业内值得信赖的合作伙伴。致力于提供卓越的海事产品与服务，通过与众多顶级品牌和产品的深度合作，建立了强大的行业地位。',
    'SealMarine is a master distributor in China for several international marine leading brands, including Suzuki marine engines, Explomar marine electric motors, SeaFirst hydraulic systems and JMP pumps, covers multiple critical areas such as marine engines and components.': '作为多个知名品牌在中国的关键经销商，包括Suzuki铃木发动机的一级代理、Explomar擎波探索电动机的总经销、韩国SeaFirst液压系统和JMP水泵的独家经销等，覆盖了船用发动机、发电机冷却部件等多个关键领域。',
    'SealMarine also keeps partnerships with a lot of brands around world such as: Canada\'s Scepter, Italy\'s CANSB, Germany\'s Torqeedo & Textron Moto, and Austria\'s Aquamot and more, so we can offer professional marine solutions to customers around China. In addition, we are collaborating with well-known boat brands from the US and abroad, such as Misty Harbor & Caravelle boat group, provide customers with a wide selection of high-quality boats & yachts.': '同时，与加拿大Scepter、意大利Can、德国Torqeedo & Textron Moto以及奥地利Aquamot等国际品牌保持紧密的合作伙伴关系，为客户提供专业的海事解决方案。除此之外， 还与美国Misty Harbor & Caravelle boat group等国内外知名船只品牌携手，致力于为客户带来更多高品质游艇的选择。',
    'Customer satisfaction always be the core concept of SealMarine, win-win policy is our way of business philosophy. By continuously improving products and services, we have earned widespread trust among customers. Future ahead, we will keep going forward on cooperation and development, seeking high quality marine products and enhance service, providing perfect user experience for our customers!': '始终将客户的满意度作为工作的核心，通过持续优化产品和服务，赢得了广泛的客户信赖。未来，将继续秉承合作与发展的原则，不断追求卓越，为客户带来更加完善的海事产品及服务体验。',
    'Room 101, Building H, South District, Xinhaiyi Science and Technology Park, No. 369 Jingmao Road, Suzhou Industrial Park': '苏州市工业园区泾茂路369号新海宜科技园南区H幢101室',
    '© 2024 SealMarine - Suzhou Xilum Trading Co., Ltd. All rights reserved': '© 2024 SealMarine - 苏州希勒姆贸易有限公司 版权所有',
    'Ask a question': '提出问题',
    'Website Notice': '网站通知',
    'legal information': '法律信息',
    'data privacy': '数据隐私',
    'Reporting system': '举报系统',
    'Su ICP No. 2021007876': '苏ICP备2021007876号',
    'Privacy settings': '隐私设置',
    'Tell us your opinions and thoughts': '告诉我们您的意见和想法',
    '2022 Suzuki outboard motor product briefing - Jiangsu region': '2022年铃木船外机产品说明会-江苏地区',
    '2024 Weishan Lake Suzuki After sales Service Event': '2024年微山湖铃木售后服务活动',
    'Contact Information': '联系方式',
    'Our professional technical team is skilled in solving problems such as product selection, product information consultation, and product application, providing you with technical support services for all products of Suzhou Hilem Trading': '我们专业的技术团队，擅于解决产品选型、产品信息咨询、产品应用等问题，为您提供苏州希勒姆贸易全线产品的技术支持服务',
    'Service Time': '服务时间',
    '09:00-17:00 (weekdays)': '09:00-17:00（工作日)',
    'Electronic documents': '电子文档',
    'Download electronic documents': '下载电子文档',
    'Increasing the low-speed output of the AC generator eliminates the need to worry about high energy consumption of fishing boats, making it an ideal choice for your sea fishing journey': '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
    'Electrification and intelligent pure electric drive for high-energy propulsion of water travel': '高能推进水上出行的电气化与智能化纯电驱动',
    '15.75 *15 / 3 blades (Extreme speed)14-1/2 *25 /3 blades (Perfect acceleration)14.6*19 /4 blades (Quiet)': '15.75 *15/3叶片(极端速度)14-1/2*25/3叶片(完美加速)14.6*19/4叶片(安静)',
    '2020 Suzuki outboard motor excellent dealer award ceremony':'2020年铃木船外机优秀经销商颁奖典礼' ,
    '2021 Suzuki outboard motor excellent dealer award ceremony':'2021年铃木船外机优秀经销商颁奖典礼' ,
    '2022 Suzuki Offshore Engine National Dealer Conference':'2022年铃木船外机全国经销商大会',
    '2022 Suzhou Lianhua Island After sales Service Activity':'2022年苏州莲花岛售后服务活动',
    '2023 Suzuki outboard motor excellent dealer award ceremony':'2023年铃木船外机优秀经销商颁奖典礼',

}
