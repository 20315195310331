import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'

Vue.use(VueRouter)

export const routes = [
    // {
    //     path: '/login',
    //     component: () => import('@/views/login/index'),
    //     hidden: true
    // },
    {
        path: '/',
        component: layout,
        redirect: '/homepage',
        hidden: true,
        // meta: { title: '首页', icon: 'el-icon-s-home' },
        // component: () => import('@/views/Workbench/index'),
        children: [{
            path: '/homepage',
            name: 'Homepage',
            component: () => import('@/views/homepage/index'),
            // meta: {title: '首页', icon: ''}
        }]
    },
    // {
    //     path: '/homepage',
    //     component: layout,
    //     // redirect: '/homepage',
    //     component: () => import('@/views/homepage/index'),
    //     hidden: true
    // },
    {
        path: '/homepage',
        component: layout,
        redirect: '/homepage',
        title: '产品',
        children: [
            {
                path: '/product',
                name: 'Homepage',
                component: {render(c){return c('router-view')}},
                // redirect: '/homepage',
                // component: () => import('@/views/login/index'),
                // component: () => import('@/views/product/index'),
                meta: {title: 'SUZUKI铃木船用发动机', icon: ''},
                children:[
                    {
                        path: '/product/DF30AQHL',
                        name: 'DF30AQHL',
                        title: 'DF30AQHL',
                        meta: {
                            title: 'DF30AQHL',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF30AQHL.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF30AQHL',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'L:508'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动/手动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'L:73'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'OHC'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '无电池电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: '直列 3'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '490'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '60.4x57'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '22.1'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,300-6,300'
                                },
                                {
                                    title: '操控方式',
                                    content: '舵柄'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '1.5'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '独立式(选装)25'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 14A'
                                },
                                {
                                    title: '调整方式',
                                    content: 'Gas Assisted Trim & Tilt'
                                },
                                {
                                    title: '传动比',
                                    content: '2.09:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '——'
                                },
                            ],
                            isTable: true,
                        },
                        // redirect: '/product',
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF60A',
                        name: 'DF60A',
                        title: 'DF60A',
                        meta: {
                            title: 'DF60A',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF60A.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF60A',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'S:381 L:508 X:635'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'S:102 L:104 X:107'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 12气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: '直列 3'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '941'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '72.5 x76'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '44.1'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,300-6,300'
                                },
                                {
                                    title: '操控方式',
                                    content: '遥控(机械)'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '2.7'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '独立式(选装)25'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 19A'
                                },
                                {
                                    title: '调整方式',
                                    content: '电力倾角调整'
                                },
                                {
                                    title: '传动比',
                                    content: '2.27:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '9"-17”'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF90AWQHLX',
                        name: 'DF90AWQHLX',
                        title: 'DF90AWQHLX',
                        meta: {
                            title: 'DF90AWQHLX',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF90AWQHLX.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF90AWQHLX',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'L:508 X:635'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'L:158 X:162'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 16气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: '直列 4'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '1,502'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '75 x85'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '66.2'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,300-6,300'
                                },
                                {
                                    title: '操控方式',
                                    content: '舵柄'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '4.3'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '独立式(选装)25'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 27A'
                                },
                                {
                                    title: '调整方式',
                                    content: '气动辅助倾角调整'
                                },
                                {
                                    title: '传动比',
                                    content: '2.59:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '13”- 25”(R/R)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF115B',
                        name: 'DF115B',
                        title: 'DF115B',
                        meta: {
                            title: 'DF115B',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF115B.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF115B',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'L:534X:661'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'L:188 X:192'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 16气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: '直列 4'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '2,045'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '86x88'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '84.6'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,000-6,000'
                                },
                                {
                                    title: '操控方式',
                                    content: '机械'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '5.5'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '——'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 40A'
                                },
                                {
                                    title: '调整方式',
                                    content: '电动升降和起翘'
                                },
                                {
                                    title: '传动比',
                                    content: '2.59:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '15”- 25”(R/R)17”- 23”(C/R)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF115BG',
                        name: 'DF115BG',
                        title: 'DF115BG',
                        meta: {
                            title: 'DF115BG',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF115BG.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF115BG',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'L：508 X：635'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'L: 190 X: 194'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 16气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: '直列 4'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '2,045'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '86 x88'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '84.6'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,000-6,000'
                                },
                                {
                                    title: '操控方式',
                                    content: '遥控(DBW)'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '5.5'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '——'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 40A'
                                },
                                {
                                    title: '调整方式',
                                    content: '电力倾角调整'
                                },
                                {
                                    title: '传动比',
                                    content: '2.59:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '15”-25"(R/R)17”-23”(C/R)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF140BG',
                        name: 'DF140BG',
                        title: 'DF140BG',
                        meta: {
                            title: 'DF140BG',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF140BG.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF140BG',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'L:508 X:635'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'L：188 X：192'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 16气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: '直列 4'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '2,045'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '86 x88'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '103.0'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,600-6,200'
                                },
                                {
                                    title: '操控方式',
                                    content: '遥控(DBW)'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '5.5'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '——'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 40A'
                                },
                                {
                                    title: '调整方式',
                                    content: '电力倾角调整'
                                },
                                {
                                    title: '传动比',
                                    content: '2.59:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '15”-25"(R/R)17”-23”(C/R)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF200',
                        name: 'DF200',
                        title: 'DF200',
                        meta: {
                            title: 'DF200',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF200.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF200',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'X:632 XX:759'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'X:275 XX:284'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 24气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: 'V6(550'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '3,614'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '95×85'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '147.1'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,000-6,000'
                                },
                                {
                                    title: '操控方式',
                                    content: '机 械'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '8.0'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '——'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 54A'
                                },
                                {
                                    title: '调整方式',
                                    content: '电动升降和起翘'
                                },
                                {
                                    title: '传动比',
                                    content: '2.29:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '15°-27.5”(R/R)15"-26”(C/R)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF250',
                        name: 'DF250',
                        title: 'DF250',
                        meta: {
                            title: 'DF250',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF250.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF250',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'X:632 XX:759'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'X:275 XX:284'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 24气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: 'V6'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '3,614'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '95×85'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '183.9'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,500-6,100'
                                },
                                {
                                    title: '操控方式',
                                    content: '机 械'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '8'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '——'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 54A'
                                },
                                {
                                    title: '调整方式',
                                    content: '电动升降和起翘'
                                },
                                {
                                    title: '传动比',
                                    content: '2.29:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '15°~27,5”(R/R)15-27.5”(C/R'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF300AP',
                        name: 'DF300AP',
                        title: 'DF300AP',
                        meta: {
                            title: 'DF300AP',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF300AP.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF300AP',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'L:508 X:635 XX:762'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'L:284X:290 XX:299'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 24气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: 'V6(55°)'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '4,028'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '98x89'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '220.7'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,700-6,300'
                                },
                                {
                                    title: '操控方式',
                                    content: '遥控 (DBW)'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '8.0'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '——'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 54A'
                                },
                                {
                                    title: '调整方式',
                                    content: '电力倾角调整'
                                },
                                {
                                    title: '传动比',
                                    content: '2.08:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '15”-27.5”(R/R)17”-26”(C/R)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF325A',
                        name: 'DF325A',
                        title: 'DF325A',
                        meta: {
                            title: 'DF325A',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF325A.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 23,
                            heading: {
                                title: '规格',
                                content: 'DF325A',
                            },
                            tableData: [
                                {
                                    title: '建议艉板高度 mm',
                                    content: 'X:635 XX:762'
                                },
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '重量 kg*',
                                    content: 'X:330 XX:339'
                                },
                                {
                                    title: '发动机类型',
                                    content: 'DOHC 24气门'
                                },
                                {
                                    title: '燃油供给系统',
                                    content: '电子燃油喷射'
                                },
                                {
                                    title: '气缸',
                                    content: 'V6(55°)'
                                },
                                {
                                    title: '活塞排量 cm3',
                                    content: '4,390'
                                },
                                {
                                    title: '缸径X冲程 mm',
                                    content: '98x97'
                                },
                                {
                                    title: '最大输出功率 kw',
                                    content: '239'
                                },
                                {
                                    title: '全节气门工作范围rpm',
                                    content: '5,300-6,300'
                                },
                                {
                                    title: '操控方式',
                                    content: '遥控(DBW)'
                                },
                                {
                                    title: '油底壳容量L',
                                    content: '8.0'
                                },
                                {
                                    title: '推荐燃油',
                                    content: '最小辛烷值为 91(研究法辛烷值)最小泵法辛烷值为 87(研究法辛烷值与马达法辛烷值的平均值)(仅用于北美)'
                                },
                                {
                                    title: '燃油箱容量L',
                                    content: '独立式(选装)25'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 54A'
                                },
                                {
                                    title: '调整方式',
                                    content: '电力倾角调整'
                                },
                                {
                                    title: '传动比',
                                    content: '2.29:1'
                                },
                                {
                                    title: '档位',
                                    content: 'F-N-R'
                                },
                                {
                                    title: '排气',
                                    content: '通过螺旋桨桨毂排气'
                                },
                                {
                                    title: '螺旋桨选型(螺距 )',
                                    content: '12”- 31.5"'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/DF350A',
                        name: 'DF350A',
                        title: 'DF350A',
                        meta: {
                            title: 'DF350A',
                            text: '一种与大自然和海洋相匹敌的力量，代表着铃木身份和传承的展现。我们致力于终极海洋创新的热情和承诺的一个象征。',
                            content: '增加交流发电机 低速输出 无需再担心渔船高能耗 是您海钓之旅的理想选择',
                            productImg: require('@/assets/SUZUKI/DF350A.jpg'),
                            // img: require('@/assets/SUZUKI/2024-suziki.jpg'),
                            // file: '2024Outboard-325-20240103.pdf',
                            enFile: '4Stroke_General_Catalogue2024.pdf',
                            zhFile: '2024Outboard-325-20240103.pdf',
                            cell: 20,
                            heading: {
                                title: '规格',
                                content: 'DF350A',
                            },
                            tableData: [
                                {
                                    title: '启动系统',
                                    content: '电动'
                                },
                                {
                                    title: '推荐艉肋高度(mm)',
                                    content: 'X:635XX:762'
                                },
                                {
                                    title: '重量千克*1',
                                    content: 'X:330 XX:338'
                                },
                                {
                                    title: '气阀机构',
                                    content: '双顶置凸轮轴 24-阀'
                                },
                                {
                                    title: '气阀机构驱动器',
                                    content: '链条'
                                },
                                {
                                    title: '排量(cm3)',
                                    content: '4,390'
                                },
                                {
                                    title: '最大输出功率(Kw)',
                                    content: '257.4'
                                },
                                {
                                    title: '缸径和冲程(mm)',
                                    content: '98x997'
                                },
                                {
                                    title: '工作范围(rpm)',
                                    content: '5,700-6,300'
                                },
                                {
                                    title: '燃油输送系统',
                                    content: '电喷系统'
                                },
                                {
                                    title: '油底壳容量(L)',
                                    content: '8.0'
                                },
                                {
                                    title: '交流发电机',
                                    content: '12V 54A'
                                },
                                {
                                    title: '升降类型',
                                    content: '电动升降和起跷'
                                },
                                {
                                    title: '传动比',
                                    content: '2.29:1'
                                },
                                {
                                    title: '控制系统',
                                    content: '线传驱动'
                                },
                                {
                                    title: '推荐燃油*2',
                                    content: 'RON94/AKI89'
                                },
                                {
                                    title: '螺旋桨选择(螺距)',
                                    content: '12”-31.5”'
                                }
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage1',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'EXPLOMAR擎波探索电动机', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children:[
                    {
                        path: '/product/电动机',
                        name: '电动机',
                        title: '电动机',
                        meta: {
                            title: '电动机',
                            text: '高能推进水上出行的电气化与智能化纯电驱动',
                            content: '高能推进水上出行的电气化与智能化纯电驱动',
                            productImg: require('@/assets/EXPLOMAR/电动舷外机图片/ElectricOutboard01.png'),
                            // img: require('@/assets/EXPLOMAR/img.png'),
                            enFile: 'ProductBrochure-PPTENGVERSION.pdf',
                            zhFile: 'product_info_chinese.pdf',
                            cell: 14,
                            heading: {
                                title: '规格',
                                high: 'WAVE 300',
                                middle: 'WAVE 150+',
                                low: 'WAVE 70+',
                            },
                            tableData: [
                                {
                                    title: '输入电压',
                                    high: '540v',
                                    middle: '540v',
                                    low: '350v',
                                },
                                {
                                    title: '转速范围(螺旋浆)',
                                    high: '0-3800rpm',
                                    middle: '0-3000rpm',
                                    low: '0-3500rpm',
                                },
                                {
                                    title: '最大输出扭矩',
                                    high: '655N.m',
                                    middle: '530N.m',
                                    low: '360N.m',
                                },
                                {
                                    title: '尺寸(宽度*深度*高度)',
                                    high: '52x97x161cm(20”)',
                                    middle: '52x97x161cm(20”)',
                                    low: '52x80x130cm(22.5”)',
                                },
                                {
                                    title: '控制系统',
                                    high: 'SmartCaptain智驾系统',
                                    middle: 'SmartCaptain智驾系统',
                                    low: '前操与后操',
                                },
                                {
                                    title: '轴长',
                                    high: '20”(508mm)25”(635mm)30”(762mm)',
                                    middle: '20”(508mm)25”(635mm)30”(762mm)',
                                    low: '22.5”(571.5mm)',
                                },
                                {
                                    title: '发动机',
                                    high: 'AFPMM/轴向通量永磁电机',
                                    middle: 'AFPMM/轴向通量永磁电机',
                                    low: 'PMSM(X针)永磁同步电机(X针)',
                                },
                                {
                                    title: '起翘角度',
                                    high: '81°(-6° to 75”)',
                                    middle: '81°(-6° to 75”)',
                                    low: '81°(-6° to 75”)',
                                },
                                {
                                    title: '配平角度',
                                    high: '22°(-6° to 16°)',
                                    middle: '22°(-6° to 16°)',
                                    low: '——',
                                },
                                {
                                    title: '转向角度',
                                    high: '-30° to 30°',
                                    middle: '-30° to 30°',
                                    low: '-30° to 30°',
                                },
                                {
                                    title: '冷却系统',
                                    high: '专利闭式液冷系统',
                                    middle: '专利闭式液冷系统',
                                    low: '专利闭式液冷系统',
                                },
                                {
                                    title: '建议螺旋桨',
                                    high: '15.75 *15/3叶片(极端速度)14-1/2*25/3叶片(完美加速)14.6*19/4叶片(安静)',
                                    middle: '15.75 *15/3叶片(极端速度)14-1/2*25/3叶片(完美加速)14.6*19/4叶片(安静)',
                                    low: '13 1/2*15*3',
                                },
                            ],
                            isTable: true,
                            multiple: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/半固态电池HEDB240',
                        name: '半固态电池HEDB240',
                        title: '半固态电池HEDB240',
                        meta: {
                            title: '半固态电池HEDB240',
                            text: '高能推进水上出行的电气化与智能化纯电驱动',
                            content: '高能推进水上出行的电气化与智能化纯电驱动',
                            productImg: require('@/assets/EXPLOMAR/电池图片/HEDB240.png'),
                            // img: require('@/assets/EXPLOMAR/img.png'),
                            enFile: 'ProductBrochure-PPTENGVERSION.pdf',
                            zhFile: 'product_info_chinese.pdf',
                            cell: 15,
                            heading: {
                                title: '规格',
                                content: '半固态电池HEDB240',
                            },
                            tableData: [
                                {
                                    title: '电池容量',
                                    content: '240kWh'
                                },
                                {
                                    title: '重量',
                                    content: '1000kg'
                                },
                                {
                                    title: '电池类型',
                                    content: '半固态电池'
                                },
                                {
                                    title: '额定电压',
                                    content: '568V'
                                },
                                {
                                    title: '能量密度',
                                    content: '240Wh/kg'
                                },
                                {
                                    title: '工作温度',
                                    content: '-30℃ to 55℃'
                                },
                                {
                                    title: '包装材质',
                                    content: '不锈钢'
                                },
                                {
                                    title: '冷却系统',
                                    content: '闭式液冷系统'
                                },
                                {
                                    title: '外壳防护等级',
                                    content: 'IP68'
                                },
                                {
                                    title: '尺寸(宽度*深度*高度)',
                                    content: '1906*700-160mm *4'
                                },
                                {
                                    title: '充电率',
                                    content: '0.5C'
                                },
                                {
                                    title: '放电率',
                                    content: '0.63-0.94C'
                                },
                                {
                                    title: '充电器',
                                    content: '7kW直流电 Max 120kW 直流电(两小时内充满电)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/三元锂电池HEDB125',
                        name: '三元锂电池HEDB125',
                        title: '三元锂电池HEDB125',
                        meta: {
                            title: '三元锂电池HEDB125',
                            text: '高能推进水上出行的电气化与智能化纯电驱动',
                            content: '高能推进水上出行的电气化与智能化纯电驱动',
                            productImg: require('@/assets/EXPLOMAR/电池图片/HEDB125.png'),
                            // img: require('@/assets/EXPLOMAR/img.png'),
                            enFile: 'ProductBrochure-PPTENGVERSION.pdf',
                            zhFile: 'product_info_chinese.pdf',
                            cell: 15,
                            heading: {
                                title: '规格',
                                content: '三元锂电池HEDB125',
                            },
                            tableData: [
                                {
                                    title: '电池容量',
                                    content: '125kWh'
                                },
                                {
                                    title: '重量',
                                    content: '720kg'
                                },
                                {
                                    title: '电池类型',
                                    content: '三元锂'
                                },
                                {
                                    title: '额定电压',
                                    content: '540V'
                                },
                                {
                                    title: '能量密度',
                                    content: '175Wh/kg'
                                },
                                {
                                    title: '工作温度',
                                    content: '-20℃ to 55℃'
                                },
                                {
                                    title: '包装材质',
                                    content: '不锈钢'
                                },
                                {
                                    title: '冷却系统',
                                    content: '闭式液冷系统'
                                },
                                {
                                    title: '外壳防护等级',
                                    content: 'IP68'
                                },
                                {
                                    title: '尺寸(宽度*深度*高度)',
                                    content: '2320*700-320mm'
                                },
                                {
                                    title: '充电率',
                                    content: '1C'
                                },
                                {
                                    title: '放电率',
                                    content: '1-2C'
                                },
                                {
                                    title: '充电器',
                                    content: '7kW直流电 Max 125kW 直流电(两小时内充满电)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/三元锂电池HEDB40',
                        name: '三元锂电池HEDB40',
                        title: '三元锂电池HEDB40',
                        meta: {
                            title: '三元锂电池HEDB40',
                            text: '高能推进水上出行的电气化与智能化纯电驱动',
                            content: '高能推进水上出行的电气化与智能化纯电驱动',
                            productImg: require('@/assets/EXPLOMAR/电池图片/HEDB40.png'),
                            // img: require('@/assets/EXPLOMAR/img.png'),
                            enFile: 'ProductBrochure-PPTENGVERSION.pdf',
                            zhFile: 'product_info_chinese.pdf',
                            cell: 15,
                            heading: {
                                title: '规格',
                                content: '三元锂电池HEDB40',
                            },
                            tableData: [
                                {
                                    title: '电池容量',
                                    content: '40kWh'
                                },
                                {
                                    title: '重量',
                                    content: '270kg'
                                },
                                {
                                    title: '电池类型',
                                    content: '三元锂'
                                },
                                {
                                    title: '额定电压',
                                    content: '347.5V'
                                },
                                {
                                    title: '能量密度',
                                    content: '136Wh/kg'
                                },
                                {
                                    title: '工作温度',
                                    content: '-20℃ to 55℃'
                                },
                                {
                                    title: '包装材质',
                                    content: '不锈钢'
                                },
                                {
                                    title: '冷却系统',
                                    content: '闭式液冷系统'
                                },
                                {
                                    title: '外壳防护等级',
                                    content: 'IP68'
                                },
                                {
                                    title: '尺寸(宽度*深度*高度)',
                                    content: '1500*510-300mm'
                                },
                                {
                                    title: '充电率',
                                    content: '1C'
                                },
                                {
                                    title: '放电率',
                                    content: '1-2C'
                                },
                                {
                                    title: '充电器',
                                    content: '7kW直流电 Max 40kW 直流电(两小时内充满电)'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/充电桩',
                        name: '充电桩',
                        title: '充电桩',
                        meta: {
                            title: '充电桩',
                            text: '高能推进水上出行的电气化与智能化纯电驱动',
                            content: '高能推进水上出行的电气化与智能化纯电驱动',
                            productImg: require('@/assets/EXPLOMAR/充电装备图片/ChargingCollection.png'),
                            // img: require('@/assets/EXPLOMAR/img.png'),
                            enFile: 'ProductBrochure-PPTENGVERSION.pdf',
                            zhFile: 'product_info_chinese.pdf',
                            cell: 13,
                            heading: {
                                title: '规格',
                                high: '7kW充电桩',
                                middle: '40kW充电桩',
                                low: '120kW充电桩',
                            },
                            tableData: [
                                {
                                    title: '机身尺寸',
                                    high: '490*385*185mm(不含枪头、提手、脚轮)',
                                    middle: '680*230*450mm(包含轮子)',
                                    low: '820*682*1920mm',
                                },
                                {
                                    title: '重量',
                                    high: '26kg(参考值，含枪线)',
                                    middle: '62kg',
                                    low: '387kg',
                                },
                                {
                                    title: '额定输出功率',
                                    high: '7kW',
                                    middle: '40kW',
                                    low: '120kW',
                                },
                                {
                                    title: '输入电压范围',
                                    high: '90-265Vac:230Vac(额定电压)',
                                    middle: '260-475Vac',
                                    low: '400V+10%Vac',
                                },
                                {
                                    title: '输出电压范围',
                                    high: '50-750Vdc',
                                    middle: '200-1000Vdc',
                                    low: '200-1000Vdc',
                                },
                                {
                                    title: '输入电流范围',
                                    high: '0-32A',
                                    middle: '0-64A',
                                    low: '0-180A',
                                },
                                {
                                    title: '输出电流范围',
                                    high: '0-20A',
                                    middle: '0-120A',
                                    low: '0-200A',
                                },
                                {
                                    title: '工作频率',
                                    high: '45-65Hz',
                                    middle: '45-65Hz',
                                    low: '50/60Hz',
                                },
                                {
                                    title: '充电接口',
                                    high: 'CCS2',
                                    middle: 'CCS2',
                                    low: 'CCS2*2',
                                },
                                {
                                    title: '冷却方式',
                                    high: '风冷',
                                    middle: '风冷',
                                    low: '风冷',
                                },
                                {
                                    title: '防护等级',
                                    high: 'IP54',
                                    middle: 'IP54',
                                    low: 'IP54',
                                },
                            ],
                            isTable: true,
                            multiple: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage2',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'JMP水泵', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children:[
                    {
                        path: '/product/FLEXIBLE IMPELLER PUMPS',
                        name: 'FLEXIBLE IMPELLER PUMPS',
                        title: 'FLEXIBLE IMPELLER PUMPS',
                        meta: {
                            title: 'FLEXIBLE IMPELLER PUMPS',
                            text: `As you know, Flexible impellers are the most important part in Flexible impeller pumps.We improve the impeller performance by special surlace coating, decreasing wear and tear so the impellers canast a long time.
We recommend you should change the impeller every year, We can guarantee that you will have greatsatisfaction with JMP flexible impellers.`,
                            content: `As you know, Flexible impellers are the most important part in Flexible impeller pumps.We improve the impeller performance by special surlace coating, decreasing wear and tear so the impellers canast a long time.
We recommend you should change the impeller every year, We can guarantee that you will have greatsatisfaction with JMP flexible impellers.`,
                            // productImg: require('@/assets/JMP/clutchpump.jpg'),
                            // img: require('@/assets/JMP/2023_JMP_CATALOG-1.png'),
                            imgList: [
                                require('@/assets/JMP/Company.jpg'),
                                require('@/assets/JMP/seawaterpump_banner.jpg'),
                                require('@/assets/JMP/SPAREPARTS_2.jpg'),
                            ],
                            video:  `${process.env.BASE_URL}`+'static/video/jmp.mp4',
                            file: '2023_JMP_CATALOG.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/FLEXIBLE IMPELLERS',
                        name: 'FLEXIBLE IMPELLERS',
                        title: 'FLEXIBLE IMPELLERS',
                        meta: {
                            title: 'FLEXIBLE IMPELLERS',
                            text: `Synthetic and Nitrile materials are availablefor most pumps. Synthetic impellers are the mostcommon uses and suitable for small amount of bilge.Nitrile impellers offer better resistance for bilge.Please contact us if you want to use for other applications. `,
                            content: `Synthetic and Nitrile materials are availablefor most pumps. Synthetic impellers are the mostcommon uses and suitable for small amount of bilge.Nitrile impellers offer better resistance for bilge.Please contact us if you want to use for other applications. `,
                            // productImg: require('@/assets/JMP/Impeller_banner.jpg'),
                            // img: require('@/assets/JMP/2023_JMP_CATALOG-1.png'),
                            imgList: [
                                require('@/assets/JMP/IMG_3865.jpg'),
                                require('@/assets/JMP/Impeller_banner.jpg'),
                            ],
                            file: '2023_JMP_CATALOG.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage3',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'SeaFirst液压系统', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children:[
                    {
                        path: '/product/MO100H',
                        name: 'MO100H',
                        title: 'MO100H',
                        meta: {
                            title: 'MO100H',
                            text: 'SeaFirst液压系统',
                            content: 'SeaFirst液压系统',
                            // productImg: require('@/assets/SeaFirst/MO100H.jpg'),
                            imgList: [
                                require('@/assets/SeaFirst/MO100H.jpg'),
                            ],
                            video: `${process.env.BASE_URL}`+'static/video/seafirst.mp4',
                            // img: require('@/assets/SeaFirst/CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACK PLATE-1.png'),
                            file: 'CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACKPLATE.pdf',
                            cell: 12,
                            heading: {
                                title: 'Components',
                                content: 'Description',
                            },
                            tableData: [
                                {
                                    title: 'CSHD18*',
                                    content: 'Froat Mount Helim pumg 18cc'
                                },
                                {
                                    title: 'S0C2512H',
                                    content: 'Cylinder for small engine under 95HP'
                                },
                                {
                                    title: 'NHD4-SS-07*',
                                    content: 'Hydraulic Hose 1/4. 7 meter x 2 pas'
                                },
                                {
                                    title: 'SF-0IL-15',
                                    content: 'Hydraulic ail 1 liter x 2 bottles. IS0 VG 15.'
                                },
                                {
                                    title: 'DAK-300',
                                    content: 'Oil Bleeding Kit'
                                },
                                {
                                    title: 'Wedge kit',
                                    content: 'To mount pump at 20* to mounting surface'
                                },
                                {
                                    title: 'Steering Wheel',
                                    content: 'Frem 300mm up to 400mm'
                                },
                                {
                                    title: 'Dual station kit',
                                    content: 'To add an additional station of helrm'
                                },
                                {
                                    title: 'Bulkhead Mounting Kit',
                                    content: 'To help the hose to be tidied'
                                },
                                {
                                    title: 'Spare parts',
                                    content: 'To repair the bhelm pump and cylinder'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/MO150H',
                        name: 'MO150H',
                        title: 'MO150H',
                        meta: {
                            title: 'MO150H',
                            text: 'SeaFirst液压系统',
                            content: 'SeaFirst液压系统',
                            // productImg: require('@/assets/SeaFirst/MO100H.jpg'),
                            imgList: [
                                require('@/assets/SeaFirst/MO150H.jpg'),
                            ],
                            video: `${process.env.BASE_URL}`+'static/video/seafirst.mp4',
                            // img: require('@/assets/SeaFirst/CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACK PLATE-1.png'),
                            file: 'CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACKPLATE.pdf',
                            cell: 12,
                            heading: {
                                title: 'Components',
                                content: 'Description',
                            },
                            tableData: [
                                {
                                    title: 'CSHO18*',
                                    content: 'Front Mount Helm pump 18cc'
                                },
                                {
                                    title: 'SOC3016H-R1',
                                    content: 'Front Mount Outboard cylinder.'
                                },
                                {
                                    title: 'NHO4-SS-07**',
                                    content: 'Hydraulic Hose 1/4". 7 meterx2 pcs'
                                },
                                {
                                    title: 'SF-0IL-15',
                                    content: 'Hydraulic oil 1 liter x 2 bottles. lS0 VG 15.'
                                },
                                {
                                    title: 'DAK-300',
                                    content: 'Oil Bleeding Kit'
                                },
                                {
                                    title: 'Wedge kit',
                                    content: 'To mount pump at 20" to mounting surface '
                                },
                                {
                                    title: 'Steering Wheel',
                                    content: 'From 300mm up to 400mm'
                                },
                                {
                                    title: 'Dual station kit',
                                    content: 'To add an additional station of helm'
                                },
                                {
                                    title: 'Bulkhead Mounting Kit',
                                    content: 'To help the hose to be tidied'
                                },
                                {
                                    title: 'Spare parts',
                                    content: 'To repair the helm pump and cylinder'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/MO350H',
                        name: 'MO350H',
                        title: 'MO350H',
                        meta: {
                            title: 'MO350H',
                            text: 'SeaFirst液压系统',
                            content: 'SeaFirst液压系统',
                            // productImg: require('@/assets/SeaFirst/MO100H.jpg'),
                            imgList: [
                                require('@/assets/SeaFirst/MO350H.jpg'),
                            ],
                            video: `${process.env.BASE_URL}`+'static/video/seafirst.mp4',
                            // img: require('@/assets/SeaFirst/CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACK PLATE-1.png'),
                            file: 'CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACKPLATE.pdf',
                            cell: 12,
                            heading: {
                                title: 'Components',
                                content: 'Description',
                            },
                            tableData: [
                                {
                                    title: 'NSHO25*',
                                    content: 'Front Mount Helm pump 25cc'
                                },
                                {
                                    title: 'S0C3520H-R1',
                                    content: 'Front Mount Outboard cylinder.'
                                },
                                {
                                    title: 'NH06-SS-07**',
                                    content: 'Hydraulic Hose 3/8", 7 meter x 2 pcs'
                                },
                                {
                                    title: 'SF-0IL-15',
                                    content: 'Hydraulic oil 1 liter x 2 bottles. ISO VG 15.'
                                },
                                {
                                    title: '0AK-300',
                                    content: 'Oil Bleeding Kit'
                                },
                                {
                                    title: 'Wedge kit',
                                    content: 'To mount pump at 20" to mounting surface'
                                },
                                {
                                    title: 'Steering Wheel',
                                    content: 'From 300mm up to 400mm'
                                },
                                {
                                    title: 'Dual station kit',
                                    content: 'To add an additional station of helm'
                                },
                                {
                                    title: 'Bulkhead Mounting Kit',
                                    content: 'To help the hose to be tidied'
                                },
                                {
                                    title: 'Spare parts',
                                    content: 'To repair the helm pump and cylinder'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/MO700H',
                        name: 'MO700H',
                        title: 'MO700H',
                        meta: {
                            title: 'MO700H',
                            text: 'SeaFirst液压系统',
                            content: 'SeaFirst液压系统',
                            // productImg: require('@/assets/SeaFirst/MO100H.jpg'),
                            imgList: [
                                require('@/assets/SeaFirst/MO700H.png'),
                            ],
                            video: `${process.env.BASE_URL}`+'static/video/seafirst.mp4',
                            // img: require('@/assets/SeaFirst/CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACK PLATE-1.png'),
                            file: 'CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACKPLATE.pdf',
                            cell: 12,
                            heading: {
                                title: 'Components',
                                content: 'Description',
                            },
                            tableData: [
                                {
                                    title: 'NSHO25*',
                                    content: 'Front Mount Helm pump 25cc'
                                },
                                {
                                    title: 'S0C3822H-W7',
                                    content: 'Front Mount Outboard cylinder.'
                                },
                                {
                                    title: 'NH06-SS-07 **',
                                    content: 'Hydraulic Hose 3/8", 7 meter x 2 pcs'
                                },
                                {
                                    title: 'SF-0IL-15',
                                    content: 'Hydraulic oil 1 liter x 2 bottles. ISO VG 15.'
                                },
                                {
                                    title: '0AK-300',
                                    content: 'Oil Bleeding Kit'
                                },
                                {
                                    title: 'Wedge kit',
                                    content: 'To mount pump at 20" to mounting surface'
                                },
                                {
                                    title: 'Steering Wheel',
                                    content: 'From 300mm up to 400mm'
                                },
                                {
                                    title: 'Dual station kit',
                                    content: 'To add an additional station of helm'
                                },
                                {
                                    title: 'Bulkhead Mounting Kit',
                                    content: 'To help the hose to be tidied'
                                },
                                {
                                    title: 'Spare parts',
                                    content: 'To repair the helm pump and cylinder'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/MS250S-U1',
                        name: 'MS250S-U1',
                        title: 'MS250S-U1',
                        meta: {
                            title: 'MS250S-U1',
                            text: 'SeaFirst液压系统',
                            content: 'SeaFirst液压系统',
                            // productImg: require('@/assets/SeaFirst/MO100H.jpg'),
                            imgList: [
                                require('@/assets/SeaFirst/MS250S-U1.jpg'),
                            ],
                            video: `${process.env.BASE_URL}`+'static/video/seafirst.mp4',
                            // img: require('@/assets/SeaFirst/CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACK PLATE-1.png'),
                            file: 'CATALOG_2023-2024_OUTBOARD-STERNDRIVE-JACKPLATE.pdf',
                            cell: 13,
                            heading: {
                                title: 'Components',
                                content: 'Description',
                            },
                            tableData: [
                                {
                                    title: 'NSH030 *',
                                    content: 'Front Mount Helm pump 30cc'
                                },
                                {
                                    title: 'SSC3214U',
                                    content: 'Cylinder for Sterndrive'
                                },
                                {
                                    title: 'NHO6-SS-20',
                                    content: 'Hydraulic Hose 3/8", 20 meter x 1 pc, includingtwo Reusable hose coupling'
                                },
                                {
                                    title: 'SF-0IL-15',
                                    content: 'Hydraulic oil 1 liter x 4 bottles. IS0 VG 15.'
                                },
                                {
                                    title: '0AK-300',
                                    content: 'Oil Bleeding Kit'
                                },
                                {
                                    title: 'Wedge kit',
                                    content: 'To mount pump at 20" to mounting surface'
                                },
                                {
                                    title: 'Steering Wheel',
                                    content: 'From 300mm up to 400mm'
                                },
                                {
                                    title: 'Dual station kit',
                                    content: 'To add an additional station of helm'
                                },
                                {
                                    title: 'Bulkhead Mounting Kit',
                                    content: 'To help the hose to be tidied'
                                },

                                {
                                    title: 'SBK-250 Tube bracket***',
                                    content: 'To mount sterndrive cylinder for Non Power Assist'
                                },
                                {
                                    title: 'Spare parts',
                                    content: 'To repair the helm pump and cylinder'
                                },
                            ],
                            isTable: true,
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage4',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'Pretech方向系统', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children:[
                    {
                        path: '/product/Pretech CATALOGUE_compressed',
                        name: 'Pretech CATALOGUE_compressed',
                        title: 'Pretech CATALOGUE_compressed',
                        meta: {
                            title: 'Pretech CATALOGUE_compressed',
                            text: 'Pretech CATALOGUE_compressed',
                            content: 'Pretech CATALOGUE_compressed',
                            productImg: require('@/assets/Pretech/PretechCATALOGUE_compressed-1.jpg'),
                            img: require('@/assets/Pretech/PretechCATALOGUE_compressed-1.jpg'),
                            file: 'PretechCATALOGUE_compressed.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage5',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'Scepter油箱', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children:[
                    {
                        path: '/product/Secepter',
                        name: 'Scepter油箱',
                        title: 'Scepter油箱',
                        meta: {
                            title: 'Scepter油箱',
                            text: 'Scepter油箱',
                            content: 'Scepter油箱',
                            productImg: require('@/assets/Secepter/Scepter.jpg'),
                            // img: require('@/assets/Secepter/Secepter.png'),
                            file: 'Secepter.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage6',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'CANSB油箱', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children:[
                    {
                        path: '/product/CANSB',
                        name: 'CanSB-Cat-2024',
                        title: 'CanSB-Cat-2024',
                        meta: {
                            title: 'CanSB-Cat-2024',
                            text: 'CANSB油箱',
                            content: 'CANSB油箱',
                            productImg: require('@/assets/CANSB/CanSB-Cat-2024-5.png'),
                            // img: require('@/assets/CANSB/CanSB-Cat-2024-1.png'),
                            file: 'CanSB-Cat-2024.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage7',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'Torqeedo电动机', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children:[
                    {
                        path: '/product/Cruise R',
                        name: 'Cruise R',
                        title: 'Cruise R',
                        meta: {
                            title: 'Cruise R',
                            text: '更大马力、更长续航时间',
                            content: '更大马力、更长续航时间',
                            productImg: require('@/assets/Torqeedo/CruiseR.jpg'),
                            // img: require('@/assets/Torqeedo/Torqeedo-1.png'),
                            file: 'Torqeedo_product_info_2014_chinese.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/Cruise_2_T_high - Copy 2',
                        name: 'Cruise 2_T_high - Copy 2',
                        title: 'Cruise_2_T_high - Copy 2',
                        meta: {
                            title: 'Cruise_2_T_high - Copy 2',
                            text: '更大马力、更长续航时间',
                            content: '更大马力、更长续航时间',
                            productImg: require('@/assets/Torqeedo/Cruise_2_T_high - Copy 2.jpg'),
                            // img: require('@/assets/Torqeedo/Torqeedo-1.png'),
                            file: 'Torqeedo_product_info_2014_chinese.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/Cruise_4_T_high 2',
                        name: 'Cruise_4_T_high 2',
                        title: 'Cruise_4_T_high 2',
                        meta: {
                            title: 'Cruise_4_T_high 2',
                            text: '更大马力、更长续航时间',
                            content: '更大马力、更长续航时间',
                            productImg: require('@/assets/Torqeedo/Cruise_4_T_high.jpg'),
                            // img: require('@/assets/Torqeedo/Torqeedo-1.png'),
                            file: 'Torqeedo_product_info_2014_chinese.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/CruiseT_06_high',
                        name: 'CruiseT_06_high',
                        title: 'CruiseT_06_high',
                        meta: {
                            title: 'CruiseT_06_high',
                            text: '更大马力、更长续航时间',
                            content: '更大马力、更长续航时间',
                            productImg: require('@/assets/Torqeedo/CruiseT_06_high.jpg'),
                            // img: require('@/assets/Torqeedo/Torqeedo-1.png'),
                            file: 'Torqeedo_product_info_2014_chinese.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                    {
                        path: '/product/power_03_high',
                        name: 'power_03_high',
                        title: 'power_03_high',
                        meta: {
                            title: 'power_03_high',
                            text: '更大马力、更长续航时间',
                            content: '更大马力、更长续航时间',
                            productImg: require('@/assets/Torqeedo/power_03_high.jpg'),
                            // img: require('@/assets/Torqeedo/Torqeedo-1.png'),
                            file: 'Torqeedo_product_info_2014_chinese.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage8',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'Nauticus压浪板', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children:[
                    {
                        path: '/product/nauticus_catalog_2019',
                        name: 'nauticus_catalog_2019',
                        title: 'nauticus_catalog_2019',
                        meta: {
                            title: 'nauticus_catalog_2019',
                            text: 'Nauticus压浪板',
                            content: 'Nauticus压浪板',
                            productImg: require('@/assets/Nauticus/nauticus_catalog_2019-1.png'),
                            img: require('@/assets/Nauticus/nauticus_catalog_2019-1.png'),
                            file: 'nauticus_catalog_2019.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage9',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'SOLAS螺旋桨', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children: [
                    {
                        path: '/product/PWC_Catalog 2023',
                        name: 'PWC_Catalog 2023',
                        title: 'PWC_Catalog 2023',
                        meta: {
                            title: 'PWC_Catalog 2023',
                            text: 'SOLAS螺旋桨',
                            content: 'SOLAS螺旋桨',
                            productImg: require('@/assets/SOLAS/PWC_Catalog 2023-1.png'),
                            img: require('@/assets/SOLAS/PWC_Catalog 2023-1.png'),
                            file: 'PWC_Catalog2023.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage10',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'KDP螺旋桨', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children: [
                    {
                        path: '/product/KDP CATALOG 2024',
                        name: 'KDP CATALOG 2024',
                        title: 'KDP CATALOG 2024',
                        meta: {
                            title: 'KDP CATALOG 2024',
                            text: 'SOLAS螺旋桨',
                            content: 'SOLAS螺旋桨',
                            productImg: require('@/assets/KDP/KDPCATALOG2024-1.png'),
                            img: require('@/assets/KDP/KDPCATALOG2024-1.png'),
                            file: 'KDPCATALOG2024.pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            },
            {
                path: '/homepage11',
                name: 'Homepage',
                // component: () => import('@/views/homepage/index'),
                meta: {title: 'Misty Harbor船', icon: ''},
                // redirect: '/homepage',
                component: {render(c){return c('router-view')}},
                children: [
                    {
                        path: '/product/Misty Harbor Proof 080913 - 01',
                        name: 'Misty Harbor Proof 080913 - 01',
                        title: 'Misty Harbor Proof 080913 - 01',
                        meta: {
                            title: 'Misty Harbor Proof 080913 - 01',
                            text: 'Misty Harbor船',
                            content: 'Misty Harbor船',
                            productImg: require('@/assets/MistyHarbor/MistyHarborProof080913-01(1)-1.png'),
                            img: require('@/assets/MistyHarbor/MistyHarborProof080913-01(1)-1.png'),
                            file: 'MistyHarborProof080913-01(1).pdf',
                        },
                        component: () => import('@/views/product/modelDetails.vue'),
                    },
                ]
            }
        ]
    },
    {
        path: '/company',
        component: layout,
        title: '公司',
        children: [{
            path: '/company',
            name: 'company',
            component: () => import('@/views/company/index'),
            meta: {title: '公司', icon: ''}
        }]
    },
    {
        path: '/activity',
        component: layout,
        title: '市场与活动',
        children: [{
            path: '/activity',
            name: '',
            component: () => import('@/views/activity/index'),
            meta: {title: '市场与活动', icon: ''}
        }]
    },
    {
        path: '/support',
        component: layout,
        title: '服务与支持',
        children: [{
            path: '/support',
            name: '',
            component: () => import('@/views/support/index'),
            meta: {title: '服务与支持', icon: ''}
        }]
    },


    //
    // {
    //     path: '/',
    //     component: layout,
    //     redirect: '/workbench',
    //     // meta: { title: '首页', icon: 'el-icon-s-home' },
    //     // component: () => import('@/views/Workbench/index'),
    //     children: [{
    //         path: 'workbench',
    //         name: 'Workbench',
    //         component: () => import('@/views/Workbench/index'),
    //         meta: {title: '首页', icon: 'el-icon-s-home'}
    //     }]
    // },
    // {
    //     path: '/combination',
    //     component: layout,
    //     children: [{
    //         path: '/combination',
    //         name: 'Combination',
    //         component: () => import('@/views/combination/index'),
    //         meta: {title: '文字组合及播放管理', icon: 'el-icon-s-tools'}
    //     }]
    // },
    // {
    //     path: '/broadcast',
    //     component: layout,
    //     children: [{
    //         path: '/broadcast',
    //         name: 'Broadcast',
    //         component: () => import('@/views/broadcast/index'),
    //         meta: {title: '文字播报及定时音乐', icon: 'el-icon-s-tools'}
    //     }]
    // },
    // {
    //     path: '/banner',
    //     component: layout,
    //     children: [{
    //         path: '/banner',
    //         name: 'Banner',
    //         component: () => import('@/views/banner/index'),
    //         meta: {title: 'banner', icon: 'el-icon-picture'}
    //     }]
    // },
    // {
    //     path: '/content',
    //     component: layout,
    //     redirect: '/content/paper',
    //     name: 'Content',
    //     meta: {title: '期刊管理', icon: 'el-icon-s-help'},
    //     children: [
    //         {
    //             path: '/content/periodical',
    //             name: 'Periodical',
    //             component: () => import('@/views/content/periodical/index'),
    //             meta: {title: 'SEI期刊', icon: 'periodical'}
    //         },
    //         {
    //             path: '/content/paper',
    //             name: 'Paper',
    //             component: () => import('@/views/content/paper/index'),
    //             meta: {title: '优质论文', icon: 'paper'}
    //         }
    //     ]
    // },
    // {
    //     path: '/meeting',
    //     component: layout,
    //     children: [{
    //         path: '/meeting',
    //         name: 'Meeting',
    //         component: () => import('@/views/meeting/index'),
    //         meta: {title: '学术会议', icon: 'el-icon-s-promotion'}
    //     }]
    // },
    // {
    //     path: '/user',
    //     component: layout,
    //     // redirect: '/user',
    //     children: [{
    //         path: '/user',
    //         name: 'User',
    //         component: () => import('@/views/user/index'),
    //         meta: {title: '用户管理', icon: 'el-icon-user-solid'}
    //     }]
    // },
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    }
]

const createRouter = () => new VueRouter({
    // mode: 'history', // require service support
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: routes
})
const router = createRouter()

// 重置路由方法
export function resetRouter() {
    // 得到一个全新的router实例对象
    const newRouter = createRouter()
    // 使用新的路由记录覆盖掉老的路由记录
    router.matcher = newRouter.matcher
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
// const router = new VueRouter({
//   routes
// })

export default router
