<template>
  <div class="nav-menu">
    <div class="head">
<!--      <el-image :src="`@/assets/LOGO.jpg`"></el-image>  `${process.env.BASE_URL}static/${this.meta.file}` -->
      <img :src= "BASE_URL" alt="" />
<!--      <img src="/static/img/img.png" alt="" />-->
      <div class="head-right">
        <el-input class="input-with-select" :placeholder="$t(`search`)">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div>
        <span  :style="{color : $i18n.locale == 'en' ?'#999':'' }" @click="changeLanguage('zh')">中</span>
        &nbsp;/&nbsp;
        <span :style="{color : $i18n.locale == 'zh' ?'#999':'' }" @click="changeLanguage('en')">EN</span>
      </div>
    </div>

    <el-menu :collapse="isCollapse" router :default-active="activeIndex" class="el-menu-vertical-demo" mode="horizontal" menu-trigger="hover" :unique-opened="true" active-text-color="#0098a1"
             @close="handleClose" @open="handleOpen">
      <!--        <div v-for="(item, index) in List" :key="index">-->

      <el-submenu class="submenu" :index="item.path"  v-for="(item, index) in List" v-if="item.title==='产品'" :key="index" :popper-append-to-body="true" >

        <template slot="title" class="top">
          <span>{{generateTitle(item.title)}}</span>
        </template>


        <div class="menu">

          <el-submenu :index="meta.path" v-for="meta in item.children" :key="meta.path" class="menu-left">
            <template slot="title">{{generateTitle(meta.meta.title)}}</template>
<!--            <el-menu-item class="title" :index="meta.path" v-for="meta in item.children" :key="meta.path" >{{ meta.meta.title }}</el-menu-item>-->
            <el-menu-item class="model" :index="model.path" v-for="model in meta.children" :key="model.name">{{generateTitle(model.title)}}</el-menu-item>
          </el-submenu>
<!--          <div class="menu-left">-->
<!--             <el-menu-item class="title" :index="meta.path" v-for="meta in item.children" :key="meta.path"  v-if="item.title==='产品'">{{ meta.meta.title }}</el-menu-item>-->
<!--          </div>-->
<!--          <div class="menu-right">-->
<!--            <div class="top" v-for="meta in item.children" :key="meta.path"  v-if="item.title==='产品'">-->
<!--              <div style="font-weight: 600;">{{ meta.meta.title }}</div>-->
<!--              <el-menu-item class="model" :index="model.path" v-for="model in meta.children" :key="model.name">{{model.title}}</el-menu-item>-->
<!--            </div>-->
<!--          </div>-->

        </div>
<!--        <el-menu-item class="title" :index="meta.path" v-for="meta in item.children" :key="meta.path"  v-if="item.title!=='产品'">{{generateTitle(meta.meta.title)}}</el-menu-item>-->
      </el-submenu>
      <el-menu-item v-for="(item, index) in List" v-if="item.title!=='产品'" :key="index" :index="item.path" class="menu-title">
        <div v-for="meta in item.children" :key="meta.path">
          <span v-if="!isCollapse" slot="title">{{generateTitle(meta.meta.title)}}</span>
        </div>
      </el-menu-item>
      <!--          <el-submenu :index="item.path"  v-if="item.meta" v-for="(item, index) in List" :key="index">-->
      <!--            <template slot="title">-->
      <!--              <i :class="item.meta.icon"></i>-->
      <!--              <span slot="title" v-if="!isCollapse">{{item.meta.title}}</span>-->
      <!--            </template>-->
      <!--            <el-menu-item :index="child.path" v-for="child in item.children" :key="child.path">-->
      <!--              <i :class="child.meta.icon"></i>-->
      <!--              <span slot="title" >{{child.meta.title}}</span>-->
      <!--            </el-menu-item>-->
      <!--          </el-submenu>-->
      <!--        </div>-->
    </el-menu>
    <!--      <div class="footer" :class="isCollapse?'open':'put'">-->
    <!--        <i class="el-icon-s-unfold" style="font-size: 18px" :class="isCollapse?'element':'animate'" @click="isCollapse=!isCollapse"></i>-->
    <!--      </div>-->
  </div>
</template>

<script>
import {createLogger} from "vuex";
import {generateTitle} from "@/utils/i18n";
export default {
  name: "NavMenu",
  data() {
    return {
      isCollapse: false,
      List: [],
      activeIndex: this.$route.path,
      BASE_URL:`${process.env.BASE_URL}static/img/logos.png`
    };
  },
  watch: {
    $route() {
      console.log('阿松大', this.$route)
      this.list()
    }
  },
  computed: {
    language() {
      return this.$route.getters.language
    }
  },
  created() {
    this.list()
    console.log(this.$router.options.routes, '阿松大', this.$route.matched)
    console.log(this.$route.path, '阿大')
  },
  methods: {
    generateTitle,
    list() {
      this.List = []
      this.$router.options.routes.forEach(item => {
        // console.log(item)
        if (!(item.hidden)) {
          //
          this.List.push(item)
          // return this.$router.options.routes
        }
      })
      if (this.$route.path === '/workbench') {
        this.activeIndex = '/'
      } else {
        this.activeIndex = this.$route.path
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("setLanguage", lang);
      this.$message.success('switch language success')
      console.log( this.$i18n.locale)
    }
  }
}
</script>


<style lang="scss" scoped>
//:deep(.el-menu--horizontal .el-menu--popup-bottom-start) {
//  //border-bottom: none;
//  //display: none;
//
//}
.el-menu-vertical-demo {
  //height: 100vh;
  padding: 0 20px;
  font-weight: 700;
  font-size: 16px;
}
:deep(.el-submenu__title) {
  color: #000000!important;
  font-weight: 700;
  font-size: 16px;
  .el-submenu__icon-arrow {
    display: none;
  }
}
:deep(.submenu .el-submenu__title):hover {
  color: #0098a1!important;
  border-bottom: 3px solid #0098a1 !important;
  transition: color .175s ease!important;
}

:deep(.menu-left  .el-submenu__title) {
  font-weight: 400!important;
}
:deep(.menu-left  .el-submenu__title):hover {
  color: #0098a1!important;
  //border-bottom: 3px solid #0098a1 !important;
  text-decoration: underline;
  transition: color .175s ease!important;
}
//
//:deep(.el-menu-item) {
//}
:deep(.menu-title):hover {
  color: #0098a1!important;
  border-bottom: 3px solid #0098a1 !important;
  transition: color .175s ease!important;
}
:deep(.model):hover {
  color: #0098a1!important;
  //border-bottom: 3px solid #0098a1 !important;
  text-decoration: underline;
  transition: color .175s ease!important;
}

:global(.el-menu--horizontal .el-menu--popup-bottom-start) {
  //min-width: 1250px;
  //margin-top: 0!important;
  //position: relative;
  //left: 0;
  //right: 0;
  //max-width: 95vw;
  //padding: 24px;
  //overflow: hidden;
  //width: 97vw;
}

.menu {
  width: 100%!important;
  height: auto!important;
  //display: flex;
  //flex-direction: row;
  padding: 0 !important;
  //padding: 1.667rem 2.778rem 3rem 1.5rem;
  //flex: 0 0 25%;
  //justify-content: space-between;
  .menu-left {
    //border-right: 1px solid #a2a7a9!important;
    padding-right: 16px;
    padding-left: 16px;
    //.title {
    //
    //}
  }
  .menu-right {
    //width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 1rem;
    //flex: 0 0 25%;
    //max-width: 25%;
    .top {
      //flex: 0 0 25%;
      //max-width: 25%;
      flex: 1;
      padding: 0 1rem 1rem 1.5rem;
    }
    .model {
      font-weight: 400;
    }
    .el-menu-item:hover {
      color: #0098a1!important;
      text-decoration: underline;
    }
  }
}
.title:hover {
  color: #0098a1!important;
  text-decoration: underline;
}
.el-menu-item {
  font-size: 16px;
  color: #000000!important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  //width: 200px;
  //min-height: 100vh;
}

.nav-menu {
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  //width: 100%;
  //height: 100vh;
}

.head {
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  display: grid;
  grid-template-areas:
        "logo gadgets"
        "nav nav";
  grid-template-columns: 1fr 2fr 100px;
  justify-items: start;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 5px;
  //padding: 15px 0;
  img {
    //max-width: 100%;
    width: 429px;
    height: 80px;
    object-fit: cover; /* 保持宽高比缩放图片，直到图片能完全覆盖容器 */
    object-position: center; /* 定义图片在容器中的位置，可以是top, bottom, left, right或center */
  }
  .head-right {
    width: 95%;

    :deep(.el-input__inner) {
      border: none;
      background: #f2f4f6;
    }

    :deep(.el-input-group__append) {
      border: none;
      background: #00838a;
      padding: 0 10px;
      color: #FFFFFF;
      font-size: 15px;
    }
  }
}

.footer {
  width: 24px;
  //position: absolute;
  //bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  transition: all 0.5s;
}

.open {
  width: 24px;
}

.put {
  width: 160px;
  justify-content: flex-end;
}

/* 初始状态 */
.element {
  transition: all 0.5s !important;
  //transition: all 250ms linear;
  //cursor: pointer; /* 将鼠标指针变为点击状态 */
}


/* 触发动画的类 */
.animate {
  transform: rotate(180deg) !important;
  transition: all 0.5s !important;
  //transform:  rotate(180deg);
}
</style>
