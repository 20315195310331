<template>
  <div class="Footer" >
    <div class="introduce">
      <div>{{$t('Suzhou SealMarine Trade Co., Ltd')}}</div>
      <div>
        {{$t('Room 101, Building H, South District, Xinhaiyi Science and Technology Park, No. 369 Jingmao Road, Suzhou Industrial Park')}}
        {{$t('© 2024 SealMarine - Suzhou Xilum Trading Co., Ltd. All rights reserved')}}
      </div>
<!--      <div>{{$t('© 2024 SealMarine - Suzhou Xilum Trading Co., Ltd. All rights reserved')}}</div>-->
      <div class="introduce-flex">
        <div class="el-icon">
          <a href="/workbench"><i class="el-icon-collection"></i>{{$t('Ask a question')}}</a>
        </div>
        <div class="el-icon">
          <a href="/workbench"><i class="el-icon-collection"></i>0512-6856 0190</a>
        </div>
        <div class="el-icon">
          <a href="/workbench"><i class="el-icon-collection"></i>sealmarine@163.com</a>
        </div>
      </div>
    </div>
    <div class="privacy">
      <a href="/workbench">© 2024 SealMarine</a>
      <a href="/workbench">{{$t('Website Notice')}}</a>
      <a href="/workbench">{{$t('legal information')}}</a>
      <a href="/workbench">{{$t('data privacy')}}</a>
      <a href="/workbench">{{$t('Reporting system')}}</a>
      <a href="/workbench">{{$t('Su ICP No. 2021007876')}}</a>
      <a href="/workbench">{{$t('Privacy settings')}}</a>
      <div><a href="/workbench">{{$t('Tell us your opinions and thoughts')}}</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  created() {
    // console.log(this.$router.options.routes)
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.Footer {
  width: 100%;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5
}
.introduce {
  text-align: center;
  color: #3a3a3a;
  background-color: #edf0f3;
  //padding-top: 1rem;
  //padding-bottom: 1rem;
  div:nth-child(1) {
    font-weight: 600;
    font-size: 1.25rem
  }
}
.el-icon a{
  color: #0098a1;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}

.el-icon a:hover {
  color: rgba(0, 152, 161, 0.7);
  text-decoration: none;
}


.introduce-flex {
  display: flex;
  //display: block;
  //unicode-bidi: isolate;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  //margin-top: 1rem;
  max-width: 1100px;
}
.privacy {
  background-color: #666a6e;
  min-height: 40px;
  padding: 5px 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
  a {
    color: #FFFFFF;
    margin-left: 1rem;
    text-decoration: none;
  }
  a:hover {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
  }
  div {
    width: auto;
    margin-right: 0;
    margin-left: auto;
    a {
      background-color: #1aa2aa;
      color: #fff;
      float: none !important;
      white-space: nowrap;
      margin-left: 1rem;
      font-size: 16px;
      padding: 10px 1rem;
    }
    a:hover {
      background-color: rgba(26, 162, 170, 0.8);
      text-decoration: none;
    }
  }
}
</style>
