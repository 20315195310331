"use strict";

import Vue from 'vue';
import axios from "axios";
import { Message} from 'element-ui';
import store from '@/store'
import { getToken } from '@/utils/auth'
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// if (store.getters.token) {
//   // let each request carry token
//   // ['X-Token'] is a custom headers key
//   // please modify it according to the actual situation
//   config.headers['X-Token'] = getToken()
// }


let config = {
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};
// 设置post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
// axios.defaults.headers.post['Authorization'] = getToken() || store.getters.token
const _axios = axios.create(config);
console.log('设置post请求头', axios.defaults.headers)
_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = getToken()
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
    response => {
  // function(response) {
    // Do something with response data
    Message.success(response.data.message)
    return response.data;



  },
    error => {
  // function(error) {
    // Do something with response error
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = error.response.data.errors || '错误请求'
          break;
        case 401:
          // error.message = '未授权，请重新登录'
          error.message = error.response.data.errors || '未授权，请重新登录';
          store.dispatch('resetToken').then(() => {
            location.reload()
          })
          break;
        case 403:
          error.message = '拒绝访问'
          break;
        case 404:
          // Message.error('请求错误,未找到该资源')
          error.message = error.response.data.errors;
          // error.message = '请求错误,未找到该资源'
          // window.location.href = "/NotFound"
          break;
        case 405:
          error.message = '请求方法未允许'
          break;
        case 408:
          error.message = '请求超时'
          break;
        case 500:
          error.message = '服务器端出错'
          break;
        case 501:
          error.message = '网络未实现'
          break;
        case 502:
          error.message = '网络错误'
          break;
        case 503:
          error.message = '服务不可用'
          break;
        case 504:
          error.message = '网络超时'
          break;
        case 505:
          error.message = 'http版本不支持该请求'
          break;
        default:
          error.message = `连接错误${error.response.status}`
      }
      // store.dispatch('resetToken').then(() => {
      //   location.reload()
      // })
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        Message.error('服务器响应超时，请刷新当前页')
      }
      error.message = '连接服务器失败'
    }

    Message.error(error.message)
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    // return Promise.resolve(error.response)
      return Promise.reject(error.response);
    // return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)
export default _axios;
// export default Plugin;
